// ==============================================================
// Auto select left navbar
// ============================================================== 
$(function () {
    var url = window.location;
    $('ul.main-menu a').filter(function () {
        var string = url,
            substring = this.href;
        if(string.toString().indexOf(substring) !== -1){
            var submenu = $(this).closest('ul.submenu');

            if (submenu.length) {
                submenu.siblings('a').addClass('active');
                submenu.parent('li').addClass('open');
            }
            return true;
        }
        return false;
    }).addClass("active");

    $('.main-menu a').on('click', function (e) {
        
            if (!$(this).hasClass("active")) {
                // hide any open menus and remove all other classes
                $("ul", $(this).parents("ul:first")).removeClass("in");
                $("a", $(this).parents("ul:first")).removeClass("active");
                
                // open our new menu and add the open class
                $(this).next("ul").addClass("in");
                $(this).addClass("active");
                
            }
            else if ($(this).hasClass("active")) {
                $(this).removeClass("active");
                $(this).parents("ul:first").removeClass("active");
                $(this).next("ul").removeClass("in");
            }
    })
    $('.main-menu >li >a.menu-parent').on('click', function (e) {
        e.preventDefault();
    });
});
