/*
 TEMPLATE NAME: Froiden Admin Panel HTML Template
 TEMPLATE URI: - http://froid.works/themeforest/sinewave/resource/
 DESCRIPTION: Froiden Admin Panel Hosting HTML Template
 VERSION: 1.0
 AUTHOR: Ajay Kumar Choudhary
 AUTHOR URL: http://codecanyon.net/user/ajay138/
 DESIGN BY: Rakesh Kumar
 */
(function () {
  'use strict';

  $(function () {
    /* Login, register and forgot password
     ========================================================================== */
    function resgisterModel() {
      $('#login').hide();
      $('#forgetPassword').hide();
      $('#signUp').fadeIn(700);
    }

    /* Sidebar Menu
     ========================================================================== */
    $(".menu").mCustomScrollbar({
      theme: "minimal-dark",
      axis: "y",
      autoHideScrollbar: true,
      // scrollEasing: 'linear',
    });

    var Accordion = function (el, multiple) {
      this.el = el || {};
      this.multiple = multiple || false;

      // Variables privadas
      var links = this.el.find('.menu-parent');
      // Evento
      links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown)
    }

    Accordion.prototype.dropdown = function (e) {
      var $el = e.data.el;
      var $this = $(this);
      var $next = $this.next();

      $next.slideToggle();
      $this.parent().toggleClass('open');

      if (!e.data.multiple) {
        $el.find('.submenu').not($next).slideUp().parent().removeClass('open');
      }
      ;
    }

    var accordion = new Accordion($('.main-menu'), false);

  //  Color picker

      $('.demo').each(function () {
          $(this).minicolors({
              control: $(this).attr('data-control') || 'hue',
              defaultValue: $(this).attr('data-defaultValue') || '',
              format: $(this).attr('data-format') || 'hex',
              keywords: $(this).attr('data-keywords') || '',
              inline: $(this).attr('data-inline') === 'true',
              letterCase: $(this).attr('data-letterCase') || 'lowercase',
              opacity: $(this).attr('data-opacity'),
              position: $(this).attr('data-position') || 'bottom left',
              swatches: $(this).attr('data-swatches') ? $(this).attr('data-swatches').split('|') : [],
              change: function (value, opacity) {
                  if (!value) return;
                  if (opacity) value += ', ' + opacity;
                  if (typeof console === 'object') {
                      console.log(value);
                  }
              },
              theme: 'bootstrap'
          });
      });
      $('#cp1').colorpicker();
      $('#cp2').colorpicker();
      $('#cp3').colorpicker({
          color: '#AA3399',
          format: 'rgb'
      });
      $('#cp4').colorpicker().on('changeColor', function (e) {
          $('body')[0].style.backgroundColor = e.color.toString(
              'rgba');
      });
      $('#cp5').colorpicker({
          color: "transparent",
          format: "hex"
      });
      $('#cp6').colorpicker({
          color: "#88cc33",
          horizontal: true
      });
      $('#cp7').colorpicker({
          color: '#ffaa00',
          container: true,
          inline: true
      });
      $('#cp9').colorpicker({
          customClass: 'colorpicker-2x',
          sliders: {
              saturation: {
                  maxLeft: 200,
                  maxTop: 200
              },
              hue: {
                  maxTop: 200
              },
              alpha: {
                  maxTop: 200
              }
          }
      });


  });

})();
