/*
 * Detect Mobile Browser
 */
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
   $('html').addClass('ismobile');
}

// $(window).load(function () {
//     /* --------------------------------------------------------
//         Page Loader
//      ---------------------------------------------------------*/
//     if(!$('html').hasClass('ismobile')) {
//         if($('.page-loader')[0]) {
//             setTimeout (function () {
//                 $('.page-loader').fadeOut();
//             }, 500);
//
//         }
//     }
// })

$(document).ready(function(){

    /* --------------------------------------------------------
        Scrollbar
    ----------------------------------------------------------*/
    function scrollBar(selector, theme, mousewheelaxis) {
        $(selector).mCustomScrollbar({
            theme: theme,
            scrollInertia: 100,
            axis:'yx',
            mouseWheel: {
                enable: true,
                axis: mousewheelaxis,
                preventDefault: true
            }
        });
    }

    if (!$('html').hasClass('ismobile')) {
        //On Custom Class
        if ($('.c-overflow')[0]) {
            scrollBar('.c-overflow', 'minimal-dark', 'y');
        }
    }

    /* --------------------------------------------------------
        Top Search
    ----------------------------------------------------------*/
    
    /* Bring search reset icon when focused */
    $('body').on('focus', '.hs-input', function(){
        $('.h-search').addClass('focused');
    });
    
    /* Take off reset icon if input length is 0, when blurred */
    $('body').on('blur', '.hs-input', function(){
        var x = $(this).val();
        
        if (!x.length > 0) {
            $('.h-search').removeClass('focused');
        } 
    });


    /* --------------------------------------------------------
        User Alerts
    ----------------------------------------------------------*/
    $('body').on('click', '[data-user-alert]', function(e) {
        e.preventDefault();
        
        var u = $(this).data('user-alert');
        $('.'+u).tab('show');
        
    });


    /* ---------------------------------------------------------
         Todo Lists
     ----------------------------------------------------------*/
    if($('#todo-lists')[0]) {

        //Pre checked items
        $('#todo-lists .acc-check').each(function () {
            if($(this).is(':checked')) {
                $(this).closest('.list-group-item').addClass('checked');
            }
        });

        //On check
        $('body').on('click', '#todo-lists .acc-check', function () {
           if($(this).is(':checked')) {
               $(this).closest('.list-group-item').addClass('checked');
           }
            else {
               $(this).closest('.list-group-item').removeClass('checked');
           }
        });
    }







    /*
     * Calendar Widget
     */
    if($('#calendar-widget')[0]) {
        (function(){
            $('#cw-body').fullCalendar({
		        contentHeight: 'auto',
		        theme: true,
                header: {
                    right: 'next',
                    center: 'title, ',
                    left: 'prev'
                },
                defaultDate: '2014-06-12',
                editable: true,
                events: [
                    {
                        title: 'All Day',
                        start: '2014-06-01',
                        className: 'bgm-cyan'
                    },
                    {
                        title: 'Long Event',
                        start: '2014-06-07',
                        end: '2014-06-10',
                        className: 'bgm-orange'
                    },
                    {
                        id: 999,
                        title: 'Repeat',
                        start: '2014-06-09',
                        className: 'bgm-lightgreen'
                    },
                    {
                        id: 999,
                        title: 'Repeat',
                        start: '2014-06-16',
                        className: 'bgm-lightblue'
                    },
                    {
                        title: 'Meet',
                        start: '2014-06-12',
                        end: '2014-06-12',
                        className: 'bgm-green'
                    },
                    {
                        title: 'Lunch',
                        start: '2014-06-12',
                        className: 'bgm-cyan'
                    },
                    {
                        title: 'Birthday',
                        start: '2014-06-13',
                        className: 'bgm-amber'
                    },
                    {
                        title: 'Google',
                        url: 'http://google.com/',
                        start: '2014-06-28',
                        className: 'bgm-amber'
                    }
                ]
            });
        })();

        //Display Current Date as Calendar widget header
        var mYear = moment().format('YYYY');
        var mDay = moment().format('dddd, MMM D');
        $('#calendar-widget .cwh-year').html(mYear);
        $('#calendar-widget .cwh-day').html(mDay);

    }

    /*
     * Weather Card
     */
    if ($('#c-weather')[0]) {
        $.simpleWeather({
            location: 'Austin, TX',
            woeid: '',
            unit: 'f',
            success: function(weather) {
                var html = '<div class="cw-current media">' +
                                '<div class="pull-left cwc-icon cwci-'+weather.code+'"></div>' +
                                '<div class="cwc-info media-body">' +
                                    '<div class="cwci-temp">'+weather.temp+'&deg;'+weather.units.temp+'</div>' +
                                    '<ul class="cwci-info">' +
                                        '<li>'+weather.city+', '+weather.region+'</li>' +
                                        '<li>'+weather.currently+'</li>' +
                                    '</ul>' +
                                '</div>' +
                            '</div>' +
                            '<div class="cw-upcoming"></div>';

                $("#c-weather").html(html);
                
                setTimeout(function() {
                    
                    
                    for(i = 0; i < 5; i++) {
                        var l = '<ul class="clearfix">' +
                                    '<li class="m-r-15">' +
                                        '<i class="cwc-icon cwci-sm cwci-'+weather.forecast[i].code+'"></i>' +
                                    '</li>' +
                                    '<li class="cwu-forecast">'+weather.forecast[i].high+'/'+weather.forecast[i].low+'</li>' +
                                    '<li>'+weather.forecast[i].text+'</li>' +
                                '</ul>';
                        
                        $('.cw-upcoming').append(l);
                    }
                });
            },
            error: function(error) { 
                $("#c-weather").html('<p>'+error+'</p>');
            }
        });
    }

    /*
     * Auto Hight Textarea
     */
    if ($('.auto-size')[0]) {
	   autosize($('.auto-size'));
    }

    /*
    * Profile Menu
    */
    $('body').on('click', '.profile-menu > a', function(e){
        e.preventDefault();
        $(this).parent().toggleClass('toggled');
	    $(this).next().slideToggle(200);
    });

    /*
     * Text Feild
     */

    //Add blue animated border and remove with condition when focus and blur
    if($('.fg-line')[0]) {
        $('body').on('focus', '.fg-line .form-control', function(){
            $(this).closest('.fg-line').addClass('fg-toggled');
        })

        $('body').on('blur', '.form-control', function(){
            var p = $(this).closest('.form-group, .input-group');
            var i = p.find('.form-control').val();

            if (p.hasClass('fg-float')) {
                if (i.length == 0) {
                    $(this).closest('.fg-line').removeClass('fg-toggled');
                }
            }
            else {
                $(this).closest('.fg-line').removeClass('fg-toggled');
            }
        });
    }

    //Add blue border for pre-valued fg-flot text feilds
    if($('.fg-float')[0]) {
        $('.fg-float .form-control').each(function(){
            var i = $(this).val();

            if (!i.length == 0) {
                $(this).closest('.fg-line').addClass('fg-toggled');
            }

        });
    }

    /*
     * Tag Select
     */
    if($('.chosen')[0]) {
        $('.chosen').chosen({
            width: '100%',
            allow_single_deselect: true
        });
    }

    /*
     * Input Slider
     */
    //Basic
    if($('.input-slider')[0]) {
        $('.input-slider').each(function(){
            var isStart = $(this).data('is-start');

            $(this).noUiSlider({
                start: isStart,
                range: {
                    'min': 0,
                    'max': 100,
                }
            });
        });
    }

    //Range slider
    if($('.input-slider-range')[0]) {
	$('.input-slider-range').noUiSlider({
	    start: [30, 60],
	    range: {
		    'min': 0,
		    'max': 100
	    },
	    connect: true
	});
    }

    //Range slider with value
    if($('.input-slider-values')[0]) {
	$('.input-slider-values').noUiSlider({
	    start: [ 45, 80 ],
	    connect: true,
	    direction: 'rtl',
	    behaviour: 'tap-drag',
	    range: {
		    'min': 0,
		    'max': 100
	    }
	});

	$('.input-slider-values').Link('lower').to($('#value-lower'));
        $('.input-slider-values').Link('upper').to($('#value-upper'), 'html');
    }

    /*
     * Input Mask
     */
    if ($('input-mask')[0]) {
        $('.input-mask').mask();
    }

    /*
     * Color Picker
     */
    // if ($('.color-picker')[0]) {
	   //  $('.color-picker').each(function(){
    //         var colorOutput = $(this).closest('.cp-container').find('.cp-value');
    //         $(this).farbtastic(colorOutput);
    //     });
    // }
    // if ($('.color-picker')[0]) {
	   //  $('.color-picker').each(function(){
    //         // var colorOutput = $(this).closest('.cp-container').find('.cp-value');
    //         // $(this).farbtastic(colorOutput);
    //         $(this).colorpicker();
    //     });
    // }

    /*
     * HTML Editor
     */
    if ($('.html-editor')[0]) {
	   $('.html-editor').summernote({
            height: 150
        });
    }

    if($('.html-editor-click')[0]) {
        //Edit
        $('body').on('click', '.hec-button', function(){
            $('.html-editor-click').summernote({
                focus: true
            });
            $('.hec-save').show();
        })

        //Save
        $('body').on('click', '.hec-save', function(){
            $('.html-editor-click').summernote('code');
            $('.html-editor-click').summernote('destroy');
            $('.hec-save').hide();
            notify('Content Saved Successfully!', 'success');
        });
    }

    //Air Mode
    if($('.html-editor-airmod')[0]) {
        $('.html-editor-airmod').summernote({
            airMode: true
        });
    }

    /*
     * Date Time Picker
     */

    //Date Time Picker
    if ($('.date-time-picker')[0]) {
	   $('.date-time-picker').datetimepicker();
    }

    //Time
    if ($('.time-picker')[0]) {
    	$('.time-picker').datetimepicker({
    	    format: 'LT'
    	});
    }

    //Date
    if ($('.date-picker')[0]) {
    	$('.date-picker').datetimepicker({
    	    format: 'DD/MM/YYYY'
    	});
    }

    /*
     * Form Wizard
     */

    if ($('.form-wizard-basic')[0]) {
    	$('.form-wizard-basic').bootstrapWizard({
    	    tabClass: 'fw-nav',
            'nextSelector': '.next',
            'previousSelector': '.previous'
    	});
    }

    /*
     * Bootstrap Growl - Notifications popups
     */
    function notify(message, type){
        $.notify({
            message: message
        },{
            type: type,
            allow_dismiss: false,
            label: 'Cancel',
            className: 'btn-xs btn-inverse',
            placement: {
                from: 'top',
                align: 'right'
            },
            delay: 2500,
            animate: {
                    enter: 'animated bounceIn',
                    exit: 'animated bounceOut'
            },
            offset: {
                x: 20,
                y: 85
            }
        });
    };

    /*
     * Waves Animation
     */
    (function(){
         Waves.attach('.btn:not(.btn-icon):not(.btn-float)');
         Waves.attach('.btn-icon, .btn-float', ['waves-circle', 'waves-float']);
        Waves.init();
    })();

    /*
     * Lightbox
     */
    if ($('.lightbox')[0]) {
        $('.lightbox').lightGallery({
            enableTouch: true
        });
    }

    /*
     * Link prevent
     */
    $('body').on('click', '.a-prevent', function(e){
        e.preventDefault();
    });

    /*
     * Collaspe Fix
     */
    if ($('.collapse')[0]) {

        //Add active class for opened items
        $('.collapse').on('show.bs.collapse', function (e) {
            $(this).closest('.panel').find('.panel-heading').addClass('active');
        });

        $('.collapse').on('hide.bs.collapse', function (e) {
            $(this).closest('.panel').find('.panel-heading').removeClass('active');
        });

        //Add active class for pre opened items
        $('.collapse.in').each(function(){
            $(this).closest('.panel').find('.panel-heading').addClass('active');
        });
    }

    /*
     * Tooltips
     */
    if ($('[data-toggle="tooltip"]')[0]) {
        $('[data-toggle="tooltip"]').tooltip();
    }

    /*
     * Popover
     */
    if ($('[data-toggle="popover"]')[0]) {
        $('[data-toggle="popover"]').popover();
    }

    /*
     * Message
     */

    //Actions
    if ($('.on-select')[0]) {
        var checkboxes = '.lv-avatar-content input:checkbox';
        var actions = $('.on-select').closest('.lv-actions');

        $('body').on('click', checkboxes, function() {
            if ($(checkboxes+':checked')[0]) {
                actions.addClass('toggled');
            }
            else {
                actions.removeClass('toggled');
            }
        });
    }

    if($('#ms-menu-trigger')[0]) {
        $('body').on('click', '#ms-menu-trigger', function(e){
            e.preventDefault();
            $(this).toggleClass('open');
            $('.ms-menu').toggleClass('toggled');
        });
    }

    //Cropper

    //Fixed width

      $('#fixed-width').cropper({
        viewMode: 1,
        dragMode: 'move',
        autoCropArea: 0.65,
        restore: false,
        guides: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false
      });

    //Full Crop Box
      $('#full-crop-box').cropper({
        viewMode: 3,
        dragMode: 'move',
        autoCropArea: 1,
        restore: false,
        modal: false,
        guides: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false
      });


    //Range of aspect ratio
      var $image = $('#aspect-ratio');
      var minAspectRatio = 0.5;
      var maxAspectRatio = 1.5;
      $image.cropper({
        ready: function () {
          var containerData = $image.cropper('getContainerData');
          var cropBoxData = $image.cropper('getCropBoxData');
          var aspectRatio = cropBoxData.width / cropBoxData.height;
          var newCropBoxWidth;
          if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
            newCropBoxWidth = cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2);
            $image.cropper('setCropBoxData', {
              left: (containerData.width - newCropBoxWidth) / 2,
              width: newCropBoxWidth
            });
          }
        },
        cropmove: function () {
          var cropBoxData = $image.cropper('getCropBoxData');
          var aspectRatio = cropBoxData.width / cropBoxData.height;
          if (aspectRatio < minAspectRatio) {
            $image.cropper('setCropBoxData', {
              width: cropBoxData.height * minAspectRatio
            });
          } else if (aspectRatio > maxAspectRatio) {
            $image.cropper('setCropBoxData', {
              width: cropBoxData.height * maxAspectRatio
            });
          }
        }
      });


     //Round crop
      function getRoundedCanvas(sourceCanvas) {
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        var width = sourceCanvas.width;
        var height = sourceCanvas.height;
        canvas.width = width;
        canvas.height = height;
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI);
        context.strokeStyle = 'rgba(0,0,0,0)';
        context.stroke();
        context.clip();
        context.drawImage(sourceCanvas, 0, 0, width, height);
        return canvas;
      }

      var $image = $('#crop-round');
      var $button = $('#button');
      var $result = $('#result');
      var croppable = false;
      $image.cropper({
        aspectRatio: 1,
        viewMode: 1,
        ready: function () {
          croppable = true;
        }
      });
      $button.on('click', function () {
        var croppedCanvas;
        var roundedCanvas;
        if (!croppable) {
          return;
        }
        // Crop
        croppedCanvas = $image.cropper('getCroppedCanvas');
        // Round
        roundedCanvas = getRoundedCanvas(croppedCanvas);
        // Show
        $result.html('<img src="' + roundedCanvas.toDataURL() + '">');
      });

    /*
     * Login
     */
    if ($('.login')[0]) {
        $('body').on('click', '.l-block [data-block]', function(e){
            e.preventDefault();

            var z = $(this).data('block');
            var t = $(this).closest('.l-block');
            var c = $(this).data('bg');

            t.removeClass('toggled');

            setTimeout(function(){
                $('.login').attr('data-lbg', c);
                $(z).addClass('toggled');
            });

        })
    }

    /*
     * Fullscreen Browsing
     */
    if ($('[data-action="fullscreen"]')[0]) {
	var fs = $("[data-action='fullscreen']");
	fs.on('click', function(e) {
	    e.preventDefault();

	    //Launch
	    function launchIntoFullscreen(element) {

		if(element.requestFullscreen) {
		    element.requestFullscreen();
		} else if(element.mozRequestFullScreen) {
		    element.mozRequestFullScreen();
		} else if(element.webkitRequestFullscreen) {
		    element.webkitRequestFullscreen();
		} else if(element.msRequestFullscreen) {
		    element.msRequestFullscreen();
		}
	    }

	    //Exit
	    function exitFullscreen() {

		if(document.exitFullscreen) {
		    document.exitFullscreen();
		} else if(document.mozCancelFullScreen) {
		    document.mozCancelFullScreen();
		} else if(document.webkitExitFullscreen) {
		    document.webkitExitFullscreen();
		}
	    }

	    launchIntoFullscreen(document.documentElement);
	    fs.closest('.dropdown').removeClass('open');
	});
    }


    /*
     * Profile Edit Toggle
     */
    if ($('[data-pmb-action]')[0]) {
        $('body').on('click', '[data-pmb-action]', function(e){
            e.preventDefault();
            var d = $(this).data('pmb-action');

            if (d === "edit") {
                $(this).closest('.pmb-block').toggleClass('toggled');
            }

            if (d === "reset") {
                $(this).closest('.pmb-block').removeClass('toggled');
            }


        });
    }

    /*
     * IE 9 Placeholder
     */
    if($('html').hasClass('ie9')) {
        $('input, textarea').placeholder({
            customClass: 'ie9-placeholder'
        });
    }

    /*
     * Print
     */
    if ($('[data-action="print"]')[0]) {
        $('body').on('click', '[data-action="print"]', function(e){
            e.preventDefault();

            window.print();
        })
    }

    /*
     * Typeahead Auto Complete
     */
     if($('.typeahead')[0]) {

          var statesArray = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
            'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii',
            'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
            'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
            'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
            'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
            'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
            'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
            'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
          ];
        var states = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: statesArray
        });

        $('.typeahead').typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        },
        {
          name: 'states',
          source: states
        });
    }


    /*
     * Wall
     */
    if ($('.wcc-toggle')[0]) {
        var z = '<div class="wcc-inner">' +
                    '<textarea class="wcci-text auto-size" placeholder="Write Something..."></textarea>' +
                '</div>' +
                '<div class="m-t-15">' +
                    '<button class="btn btn-sm btn-primary">Post</button>' +
                    '<button class="btn btn-sm btn-link wcc-cencel">Cancel</button>' +
                '</div>'


        $('body').on('click', '.wcc-toggle', function() {
            $(this).parent().html(z);
            autosize($('.auto-size')); //Reload Auto size textarea
        });

        //Cancel
        $('body').on('click', '.wcc-cencel', function(e) {
            e.preventDefault();

            $(this).closest('.wc-comment').find('.wcc-inner').addClass('wcc-toggle').html('Write Something...')
        });

    }

    /*
     * Skin Change
     */
    $('body').on('click', '[data-skin]', function() {
        var currentSkin = $('[data-current-skin]').data('current-skin');
        var skin = $(this).data('skin');

        $('[data-current-skin]').attr('data-current-skin', skin)

    });


//    Calendar

    $(document).ready(function() {
        var date = new Date();
        var m = date.getMonth();
        var y = date.getFullYear();
        var target = $('#calendar');

        target.fullCalendar({
            header: {
                right: '',
                center: '',
                left: ''
            },

            theme: false,
            selectable: true,
            selectHelper: true,
            editable: true,
            events: [
                {
                    id: 1,
                    title: 'Fusce dapibus tellus',
                    start: new Date(y, m, 1),
                    allDay: true,
                    className: 'bgm-cyan',
                    description: 'Nullam id dolor id nibh ultricies vehicula ut id elit. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
                },
                {
                    id: 2,
                    title: 'Fusce dapibus tellus',
                    start: new Date(y, m, 10),
                    allDay: true,
                    className: 'bgm-amber',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                },
                {
                    id: 3,
                    title: 'Egestas Justo',
                    start: new Date(y, m, 18),
                    allDay: true,
                    className: 'bgm-green',
                    description: ''
                },
                {
                    id: 4,
                    title: 'Bibendum Vehicula Quam',
                    start: new Date(y, m, 20),
                    allDay: true,
                    className: 'bgm-blue',
                    description: ''
                },
                {
                    id: 5,
                    title: 'Venenatis Dolor Porta',
                    start: new Date(y, m, 5),
                    allDay: true,
                    className: 'bgm-teal',
                    description: 'Sed posuere consectetur est at lobortis. Nullam quis risus eget urna mollis ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur. Donec ullamcorper nulla non metus auctor fringilla. Donec sed odio dui. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.'
                },
                {
                    id: 6,
                    title: 'Sem Pharetra',
                    start: new Date(y, m, 21),
                    allDay: true,
                    className: 'bgm-red',
                    description: 'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.'
                },
                {
                    id: 7,
                    title: 'Ullamcorper Porta',
                    start: new Date(y, m, 5),
                    allDay: true,
                    className: 'bgm-amber',
                    description: 'Malesuada Ullamcorper Nullam'
                },
                {
                    id: 8,
                    title: 'Egestas',
                    start: new Date(y, m, 5),
                    allDay: true,
                    className: 'bgm-green',
                    description: ''
                },
                {
                    id: 9,
                    title: 'Purus',
                    start: new Date(y, m, 1),
                    allDay: true,
                    className: 'bgm-green',
                    description: 'Sed posuere consectetur est at lobortis. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.'
                },
                {
                    id: 10,
                    title: 'Risus Elit',
                    start: new Date(y, m, 15),
                    allDay: true,
                    className: 'bgm-cyan',
                    description: 'Etiam porta sem malesuada magna mollis euismod. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.'
                },
                {
                    id: 11,
                    title: 'Risus Fermentum Justo',
                    start: new Date(y, m, 25),
                    allDay: true,
                    className: 'bgm-blue',
                    description: 'Vehicula Cras'
                },
                {
                    id: 12,
                    title: 'Porta Ornare Euismod',
                    start: new Date(y, m, 30),
                    allDay: true,
                    className: 'bgm-red',
                    description: ''
                },
                {
                    id: 13,
                    title: 'Amet Adipiscing',
                    start: new Date(y, m, 30),
                    allDay: true,
                    className: 'bgm-teal',
                    description: ''
                },
            ],

            dayClick: function(date) {
                var isoDate = moment(date).toISOString();

                $('#modal-new-event').modal('show');
                $('#event-name').val('');
                $('#new-event-start').val(isoDate);
                $('#new-event-end').val(isoDate);
            },

            viewRender: function (view) {
                var calendarDate = $("#calendar").fullCalendar('getDate');
                var calendarMonth = calendarDate.month();

                //Set data attribute for header. This is used to switch header images using css
                $('#calendar .fc-toolbar').attr('data-calendar-month', calendarMonth);

                //Set title in page header
                $('.block-header-calendar > h2 > span').html(view.title);
            },

            eventClick: function (event, element) {

                $('.edit-event-id').val(event.id);
                $('.edit-event-title').val(event.title);
                $('.edit-event-description').val(event.description);
                $('#modal-edit-event input[value='+event.className+']').prop('checked', true);
                $('#modal-edit-event').modal('show');
            }
        });


        //Add new Event
        $('body').on('click', '#btn-add-event', function(){
            var eventTitle = $('#new-event-title').val();

            var GenRandom =  {
                Stored: [],
                Job: function(){
                    var newId = Date.now().toString().substr(6); // or use any method that you want to achieve this string

                    if( !this.Check(newId) ){
                        this.Stored.push(newId);
                        return newId;
                    }
                    return this.Job();
                },
                Check: function(id){
                    for( var i = 0; i < this.Stored.length; i++ ){
                        if( this.Stored[i] == id ) return true;
                    }
                    return false;
                }
            };

            if (eventTitle != '') {
                $('#calendar').fullCalendar('renderEvent', {
                    id: GenRandom.Job(),
                    title: eventTitle,
                    start: $('#new-event-start').val(),
                    end:  $('#new-event-end').val(),
                    allDay: true,
                    className: $('.event-tag input:checked').val()

                },true );

                $('.form-event')[0].reset()
                $('#modal-new-event').modal('hide');
                $('#new-event-title').closest('.form-group').removeClass('has-error');
            }
            else {
                $('#new-event-title').closest('.form-group').addClass('has-error');
                $('#new-event-title').focus();
            }
        });


        //Update/Delete an Event
        $('body').on('click', '[data-calendar]', function(){
            var calendarAction = $(this).data('calendar');
            var currentId = $('.edit-event-id').val();
            var currentTitle = $('.edit-event-title').val();
            var currentDesc = $('.edit-event-description').val();
            var currentClass = $('.event-tag-edit input:checked').val();
            var currentEvent = $('#calendar').fullCalendar( 'clientEvents', currentId );

            //Update
            if(calendarAction === 'update') {
                if (currentTitle != '') {
                    currentEvent[0].title = currentTitle;
                    currentEvent[0].description = currentDesc;
                    currentEvent[0].className = currentClass;

                    $('#calendar').fullCalendar('updateEvent', currentEvent[0]);
                    $('#modal-edit-event').modal('hide');
                }
                else {
                    $('.edit-event-title').closest('.form-group').addClass('has-error');
                    $('.edit-event-title').focus();
                }
            }

            //Delete
            if(calendarAction === 'delete') {
                $('#modal-edit-event').modal('hide');

                setTimeout(function () {
                    swal({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    }).then(function() {
                        target.fullCalendar('removeEvents', currentId);
                        swal(
                            'Deleted!',
                            'Your list has been deleted.',
                            'success'
                        );
                    })
                }, 200);
            }
        });


        //Calendar views switch
        $('body').on('click', '[data-calendar-view]', function(e){
            e.preventDefault();

            $('[data-calendar-view]').removeClass('active');
            $(this).addClass('active');
            var calendarView = $(this).attr('data-calendar-view');
            target.fullCalendar('changeView', calendarView);
        });


        //Calendar Next
        $('body').on('click', '.calendar-next', function (e) {
            e.preventDefault();
            target.fullCalendar('next');
        });


        //Calendar Prev
        $('body').on('click', '.calendar-prev', function (e) {
            e.preventDefault();
            target.fullCalendar('prev');
        });

        //Inline Datepicker

        $('#datetimepicker12').datetimepicker({
            inline: true,
            sideBySide: false
        });

        $('#datetimepicker13').datetimepicker({
            inline: true,
            sideBySide: true
        });

    });

});